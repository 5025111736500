<template lang="html">
    <div class="layout--main pt-5 pb-10 md:pt-10 clientes-inmob">
      <div class="row">
        <!-- <router-link to="/homeInmo"> -->
          <button type="button" class="btn regresar w-48 flex" @click="updatePanel()">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 30 22" fill="none">
              <path d="M27.5 22V15.1643C27.5 13.75 26.9722 12.5452 25.9167 11.55C24.8611 10.5548 23.5833 10.0571 22.0833 10.0571H4.75L11.1667 16.1071L9.41667 17.7571L0 8.87857L9.41667 0L11.1667 1.65L4.75 7.7H22.0833C24.25 7.7 26.1111 8.42679 27.6667 9.88036C29.2222 11.3339 30 13.0952 30 15.1643V22H27.5Z" fill="#FF7150"/>
            </svg>
            <span class="ml-4">
              Regresar
            </span>
          </button>
       <!-- </router-link> -->
      </div>           

      <!-- /Navbar -->
      <div class="">
        <div class="router-view">
          <div class="router-content">
              <div v-if="$route.meta.breadcrumb || $route.meta.pageTitle" class="router-header flex flex-wrap items-center mb-6">
                <div
                  class="content-area__heading"
                  :class="{'pr-4 border-0 md:border-r border-solid border-grey-light' : $route.meta.breadcrumb}">
                  <h2 class="mb-1">{{ routeTitle }}</h2>
                </div>

                <!-- BREADCRUMB -->
                <vx-breadcrumb class="ml-4 md:block hidden" v-if="$route.meta.breadcrumb" :route="$route" :isRTL="$vs.rtl" />

                <!-- DROPDOWN -->
                <vs-dropdown vs-trigger-click class="ml-auto md:block hidden cursor-pointer">
                  <vs-button radius icon="icon-settings" icon-pack="feather" />

                  <vs-dropdown-menu class="w-32">
                    <vs-dropdown-item>
                      <div @click="$router.push('/pages/profile').catch(() => {})" class="flex items-center">
                        <feather-icon icon="UserIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />
                        <span>Profile</span>
                      </div>
                    </vs-dropdown-item>
                    <vs-dropdown-item>
                      <div @click="$router.push('/apps/todo').catch(() => {})" class="flex items-center">
                        <feather-icon icon="CheckSquareIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />
                        <span>Tasks</span>
                      </div>
                    </vs-dropdown-item>
                    <vs-dropdown-item>
                      <div @click="$router.push('/apps/email').catch(() => {})" class="flex items-center">
                        <feather-icon icon="MailIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />
                        <span>Inbox</span>
                      </div>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>

                </vs-dropdown>

              </div>

              <div class="">
                <div class="pt-0" id="casos">    
                  <vs-table :data="casos" max-items="10"
                    search
                    pagination
                    noDataText="No hay datos para mostrar"
                    class="w-full mt-4 md:mt-8 tabla tabla-clientes-inmob"
                    id="tablaInmo">

                    <template slot="header">
                      <div class="md:flex-grow mb-4 sm:mb-0 md:pr-4 flex flex-col md:flex-row md:flex-wrap md:items-baseline md:justify-between tabla-clientes-inmo-col">
                        <div class="mb-2 md:pr-3 md:mb-0">Base de simulaciones</div>
                          
                        

                        <div>
                          <select class="SelArrBg" aria-label="Mis casos" v-model="filtroCasos" style="border-radius: 10px;">        
                            <option value="1">Mis simulaciones</option>
                            <option value="3">Todas las simulaciones</option>
                            <option value="2">Simulación rápida y completa</option>
                            <option value="7">Precalificación Online</option>
                            <option value="5">Simulaciones del equipo</option>
                          </select>
                        </div>

                      </div> 
                    </template>

                    <template slot="thead">

                      <vs-th colspan="9" class="tabla-clientes-inmob-head">
                        <ColumnConfig :filtroCasos=filtroCasos :tabla=3 :etiqueta="'BaseClientes'" v-on:colsSelected="updateTable( $event )" v-on:Download="downloadFile( $event )" />
                      </vs-th>
                        
                    </template>

                    <template slot-scope="{data}">
                      <vs-tr class="tabla-bg-color_thead">
                        <vs-th sort-key="Folio"   v-if="searchColTable('Folio')">           Folio                 </vs-th>
                        <vs-th                    v-if="searchColTable('Origen')">          Origen                </vs-th>
                        <vs-th                    v-if="searchColTable('Fecha ingreso')">   Fecha ingreso         </vs-th>
                        <vs-th                    v-if="searchColTable('Ejecutivo')">       Ejecutivo             </vs-th>
                        <vs-th                    v-if="searchColTable('Organización')">    Organizaci&oacute;n   </vs-th>
                        <vs-th sort-key="Cliente" v-if="searchColTable('Cliente')">         Cliente               </vs-th>
                        <vs-th                    v-if="searchColTable('Contacto')">        Contacto              </vs-th>       
                        <vs-th                    v-if="searchColTable('Destino')">         Destino               </vs-th>
                        <vs-th                    v-if="searchColTable('Valor Inmueble')">  Valor del inmueble    </vs-th>
                      </vs-tr>    

                      <vs-tr class="tabla-bg-color" :data="tr" :key="indextr" v-for="(tr, indextr) in data"  :class="data[indextr].StatusCaso == 'Nuevo' && filtroCasos== '3'?'colorNuevo':''">
                        <vs-td class="" :data="data[indextr].Id" v-if="searchColTable('Folio')">
                          <a class="text-xs"  @click="handleSelected(data[indextr].Id)">{{data[indextr].Id}}</a>
                        </vs-td>
                        <vs-td class="" :data="data[indextr].Id" v-if="searchColTable('Origen')">
                          <a class="text-xs" @click="handleSelected(data[indextr].Id)">{{data[indextr].VersionOrigen}}</a> 
                        </vs-td>
                        <vs-td :data="data[indextr].Fecha" v-if="searchColTable('Fecha ingreso')">
                          <a class="text-xs" @click="handleSelected(data[indextr].Id)">{{data[indextr].Fecha}}</a>
                        </vs-td>
                        <vs-td :data="data[indextr].BrokerNombre" v-if="searchColTable('Ejecutivo')">
                          <a class="text-xs" @click="handleSelected(data[indextr].Id)">{{data[indextr].BrokerNombre}}</a>
                        </vs-td>
                        <vs-td class="" :data="data[indextr].Id"  v-if="searchColTable('Organización')">
                          <a class="text-xs" @click="handleSelected(data[indextr].Id)">{{data[indextr].Organizacion}}</a>
                        </vs-td>
                        <vs-td :data="data[indextr].SolicitanteNombre" v-if="searchColTable('Cliente')">
                          <a class="text-xs" @click="handleSelected(data[indextr].Id)">{{data[indextr].SolicitanteNombre}}</a>
                        </vs-td>
                        <vs-td :data="data[indextr].Solicitante + SolicitanteTelefonoCelular" v-if="searchColTable('Contacto')">
                          <a :href="'https://api.whatsapp.com/send?phone='+data[indextr].Lada+data[indextr].SolicitanteTelefonoCelular.replace(/\s+/g, '').replace(/-/g, '')" rel="noopener" target="_blank"  class="contact-bold"><img class="mr-2 w-6 h-6" src="@/assets/iconos/wats_icon.png" alt=""></a>
                          <a :href="'mailto:' + data[indextr].Solicitante" class="contact-bold"><img class="w-6 h-6" src="@/assets/iconos/mail.png" alt=""></a>
                        <!--  
                          DATOS ORIGINALES
                          <a :href="'mailto:' + data[indextr].Solicitante" class="contact-bold">{{data[indextr].Solicitante}}</a>
                          <a :href="'https://api.whatsapp.com/send?phone='+data[indextr].Lada+data[indextr].SolicitanteTelefonoCelular.replace(/\s+/g, '').replace(/-/g, '')" rel="noopener" target="_blank"  class="contact-bold">{{data[indextr].SolicitanteTelefonoCelular}}</a>
                        -->
                        </vs-td>
                      <!-- <vs-td :data="data[indextr].SolicitanteTelefonoCelular">
                          <a :href="'https://api.whatsapp.com/send?phone='+data[indextr].Lada+data[indextr].SolicitanteTelefonoCelular.replace(/\s+/g, '').replace(/-/g, '')" rel="noopener" target="_blank"  class="contact-bold">{{data[indextr].SolicitanteTelefonoCelular}}</a>
                        </vs-td>-->
                        <vs-td :data="data[indextr].Destino" v-if="searchColTable('Destino')">
                          <a class="text-xs" @click="handleSelected(data[indextr].Id)">{{data[indextr].DestinoNombre}}</a>
                        </vs-td>
                        <vs-td :data="data[indextr].MontoSolicitado" v-if="searchColTable('Valor Inmueble')">
                          <a class="text-xs" @click="handleSelected(data[indextr].Id)">${{data[indextr].MontoSolicitado}}</a>
                        </vs-td>
                    
                      <!-- <vs-td v-if="data[indextr].StatusCaso == 'Nuevo' && filtroCasos=='3'" :data="data[indextr].StatusCaso" style="color:#079DEF">
                          {{data[indextr].StatusCaso}}
                        </vs-td>

                        <vs-td v-if="data[indextr].StatusCaso == 'Abierto' && filtroCasos=='3'" :data="data[indextr].StatusCaso" style="color:#079DEF">
                          
                        </vs-td>

                        <vs-td v-if=" filtroCasos=='2'" :data="data[indextr].VersionOrigen" style="font-weight:bold">
                          {{data[indextr].VersionOrigen}}
                        </vs-td>

                        

                        
                        
                        <vs-td v-if=" filtroCasos!='1'" :data="data[indextr].Organizacion">
                          {{data[indextr].Organizacion}}
                        </vs-td>-->
                      </vs-tr>
                    </template>
                  </vs-table>
                
                  <div class="excel">
                    <vs-prompt title="Exportar a Excel" class="export-options" cancel-text="Cancelar" @cancle="clearFields" @accept="exportToExcel" accept-text="Exportar" @close="clearFields" :active.sync="activePrompt">
                            <input type="text" v-model="fileName" placeholder="Nombre del archivo" class="vs-input" />
                            <v-select v-model="selectedFormat" :options="formats" class="my-4" />
                            <div class="flex">
                              <span class="mr-4">Tamaño de la celda automático:</span>
                              <vs-switch v-model="cellAutoWidth">Tamaño automático</vs-switch>
                            </div>
                    </vs-prompt>
                  </div>
                
                </div>
              </div>

          </div>
        </div>
      </div>
      <!--<div class="footer">COPYRIGHT 2021 Cotizador SOC, todos los derechos reservados.</div> -->  
    </div>    
 
</template>

<script>
import TheNavbarVerticalInmobiliaria   from '@/layouts/components/navbar/TheNavbarVerticalInmobiliaria.vue'
import ColumnConfig from '@/components/customcols/InmobiliariaCustomCols.vue'

import md5 from 'js-md5';
import vSelect from 'vue-select'
export default {
  props: {
    guid: {
      type: String,
      required: false
    },
  },
  components: {
    TheNavbarVerticalInmobiliaria,
    'v-select': vSelect,
    ColumnConfig
  },
  data(){
		return{
      filtroCasos:'1',
      key:'',
			casos:[],
        casoGenerales:[],
        casoEnProceso:[],
        casoFirmados:[],
        casoDescartados:[],
      allcasos:[],
			estatus:[],
			destinos:[],
      selected:[],
      estatu:'',
      socData:null,
      socStatus:[],
      fileName: '',
      formats:['xlsx', 'csv', 'txt'],
      cellAutoWidth: true,
      selectedFormat: 'xlsx',
      headerTitle: ['Folio', 'Ejecutivo', 'Fecha Ingreso', 'Cliente','Correo','Telefono', 'Estatus','Destino','Credito'],
      headerVal: ['Id', 'BrokerNombre', 'Fecha', 'SolicitanteNombre', 'Solicitante', 'Telefono', 'EstatusNombre','DestinoNombre','MontoSolicitado'],
      popupActive: false,
      activePrompt: false,
      StatusSisecTabs:'Generales',
      inmobiliaria : false,
      soc:false,
      columnas: [
          { label: 'Folio', id: 1 },
          { label: 'Origen', id: 2 },
          { label: 'Fecha ingreso', id: 3 },
          { label: 'Ejecutivo', id: 4 },
          { label: 'Organización', id: 5 },
          { label: 'Cliente', id: 6 },
          { label: 'Contacto', id: 7 },            
          { label: 'Destino', id: 8 },
          { label: 'Valor Inmueble', id: 9 },          
      ],
      columnasSelected: [
            { label: 'Folio', id: 1 },
            { label: 'Origen', id: 2 },
            { label: 'Fecha ingreso', id: 3 },
            { label: 'Ejecutivo', id: 4 },
            { label: 'Organización', id: 5 },
            { label: 'Cliente', id: 6 },
            { label: 'Contacto', id: 7 },            
            { label: 'Destino', id: 8 },
            { label: 'Valor Inmueble', id: 9 },            
        ],
        ColumnasSelected: [],
		}
  },
	mounted(){
    if(!JSON.parse(localStorage.getItem('userInfo'))){
      this.$router.push('/login').catch(() => {})
    }else {
      this.getApikey()
      this.filtroCasos=this.$route.params.id            
      if (this.filtroCasos == '1') { // mis simulaciones
        this.getSolicitudes()
      }
      this.getDestinos()
      this.getEstatus()
    }

    if (this.$store.state.AppActiveUser.WidgetColorPrimario != '' && this.$store.state.AppActiveUser.WidgetColorPrimario) {
      this.$vs.theme({
        primary:this.$store.state.AppActiveUser.WidgetColorPrimario
      })
    }    
    this.activaPanel()
    this.$vs.loading({
      container: '#casos',
      scale: 0.6
    })
	},
  watch: {
    filtroCasos: function() {
      this.$vs.loading({
        container: '#casos',
        scale: 0.6
      })      
      if (this.filtroCasos == '1') { // mis simulaciones
        this.getSolicitudes()
      }else if(this.filtroCasos == '3' || this.filtroCasos == '5'){  // todas las simulaciones || simulaciones del equipo
        //this.getCasosSolicitados()
        this.getAllCasos()
      }else if(this.filtroCasos == '2' || this.filtroCasos == '7'){ // simulaciones rapidas y completas   | Origen Negocio Digital
        this.getCasosPorFiltro()
      }           
    },
    estatu: function() {
      if (this.estatu == null) {
        this.casos= this.allcasos
      }else {
        this.filter(this.estatu)
      }
    }
  },
	methods:{
    activaPanel(){
      this.$emit('actualiza_panel', 'HomeInmo', 'BaseClientes')
    },
    handleSelected(tr) {
      this.$router.push('/casoInmobiliaria/'+tr).catch(() => {})
    },
    filter(id){ //No se ocupa en inmobiliaria
      var newArray = this.allcasos.filter(function (el) {
        return el.Estatus == id;
      });
      this.casos=newArray
    },
    updateTable( cols ) {      
        this.ColumnasSelected = cols;                
    },
    downloadFile(){
      this.activePrompt=true;
      this.popupActive=false;
    },
    getApikey(){
      this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
    },
    IrSimulad() {
      //alert('hoal')
			this.$router.push('/nuevaCotizacionInmobiliaria').catch(() => {})
		},
    
    searchColTable( col ) { 
        let isEnabled = this.ColumnasSelected.find(e => e.label == col);
        return isEnabled;
    },   
    updatePanel(){      
      this.$emit('actualiza_panel', 'BaseClientes')
      this.$router.push('/homeInmo').catch(() => {})      
    },
		getDestinos(){
      let self=this
			var objRequestListaDestinos = {
				strApiKey: this.key,
				strMetodo: 'ListaDestinos',
			}
			this.$axios.post('/',objRequestListaDestinos,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			.then(
				response => {
					if(response.data.intEstatus == 0){
						this.destinos=response.data.objContenido
					}else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaDestinos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
				}
			).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
			})
		},
		getEstatus(){
      let self=this
			var objRequestListaCasoEstatus = {
				strApiKey: this.key,
				strMetodo: 'ListaCasoEstatus',
			}
			this.$axios.post('/',objRequestListaCasoEstatus,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			.then(
				response => {
					if(response.data.intEstatus == 0){
						this.estatus=response.data.objContenido
            this.estatus.sort((a,b) => (a.Id > b.Id) ? 1 : ((b.Id > a.Id) ? -1 : 0));
            for (var i = 0; i < this.estatus.length; i++) {
              if (this.estatus[i].Id== 1 || this.estatus[i].Id== 2 || this.estatus[i].Id== 3 || this.estatus[i].Id== 5 || this.estatus[i].Id== 11) {
                this.socStatus.push(this.estatus[i])
                this.socStatus.sort((a,b) => (a.Id > b.Id) ? 1 : ((b.Id > a.Id) ? -1 : 0));
              }
            }
					}else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaCasoEstatus',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
				}
			).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
			})
		},
    /*empieza*/
    getCasosSolicitados(){
      let self=this
			var objRequestListaCasos = {
				strApiKey: this.key,
				strMetodo: 'ListaCasosJerarquiaInmobiliaria',
        objParametros: {
           Limite:900,
           strStatus:this.StatusSisecTabs
       }
			}
			this.$axios.post('/',objRequestListaCasos,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			.then(
				response => {
					if(response.data.intEstatus == 0){
            this.casos= response.data.objContenido
            this.allcasos=this.casos

						for (var i = 0; i < this.casos.length; i++) {
							this.casos[i].MontoSolicitado= this.casos[i].MontoSolicitado.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
							for (var j = 0; j < this.destinos.length; j++) {
								if (this.destinos[j].Id == this.casos[i].Destino) {
										this.casos[i].DestinoNombre=(this.destinos[j].Nombre).toLowerCase()
								}
							}
							for (var k = 0; k < this.estatus.length; k++) {
								if (this.estatus[k].Id == this.casos[i].Estatus) {
										this.casos[i].EstatusNombre=(this.estatus[k].Nombre).toLowerCase()
								}
							}
                            if(this.casos[i].AutorizaConsultaBuro  == null){
                                this.casos[i].AutorizaConsultaBuro='No'
                            }else{
                                this.casos[i].AutorizaConsultaBuro='Si'
                            }
						}
					}else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaCasos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
          this.$vs.loading.close('#casos > .con-vs-loading')
				}
			).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
			})
    },/*termina*/
    updateStatusSIsec(statusSisec){
        this.StatusSisecTabs=statusSisec
        this.$vs.loading({
        container: '#casos',
        scale: 0.6
      })
      if (this.filtroCasos == '1') {
        this.getSolicitudes()
      }else if(this.filtroCasos == '2'){
        this.getCasosSolicitados('Generales')
      }else if(this.filtroCasos == '3'){
        this.getCasosWidget()
      }
    },
    getSolicitudes(){
      this.casos = []
      this.allcasos = []
      let self=this
			var objRequestListaCasos = {
				strApiKey: this.key,
				strMetodo: 'ListaCasos2',
			}
			this.$axios.post('/',objRequestListaCasos,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			.then(
				response => {
					if(response.data.intEstatus == 0){
            //this.casos= response.data.objContenido

            var ans = response.data.objContenido
            

            for (let index = 0; index < ans.length; index++) {
              //console.log(ans[index].VersionOrigen)
              if (ans[index].VersionOrigen != 'Web Inmobiliaria Rápida') {
                this.casos.push(ans[index])
              }              
            }

            this.allcasos=this.casos
						for (var i = 0; i < this.casos.length; i++) {
							this.casos[i].MontoSolicitado= this.casos[i].MontoSolicitado.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
							for (var j = 0; j < this.destinos.length; j++) {
								if (this.destinos[j].Id == this.casos[i].Destino) {
										this.casos[i].DestinoNombre=(this.destinos[j].Nombre).toLowerCase()
								}
							}
							for (var k = 0; k < this.estatus.length; k++) {
								if (this.estatus[k].Id == this.casos[i].Estatus) {
										this.casos[i].EstatusNombre=(this.estatus[k].Nombre).toLowerCase()
								}
							}
						}
            this.$vs.loading.close('#casos > .con-vs-loading')
					}else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaCasos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
				}
			).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
			})
		},
     getAllCasos(){     
      this.casos = []
      this.allcasos = []
      let self = this
      var ObjRequestGetAllCasos = {
        strApiKey: this.key,
				strMetodo: 'ListaCasosJerarquia',
        objParametros: {
           Limite:900  // limite 900 para prod  // pruebas 200
       }
      }
      this.$axios.post('/',ObjRequestGetAllCasos,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
				response => {
					if(response.data.intEstatus == 0){            
            //this.casos= response.data.objContenido

            var ans  = response.data.objContenido

            for (let index = 0; index < ans.length; index++) {              
              if (ans[index].VersionOrigen != 'Web Inmobiliaria Rápida') {
                this.casos.push(ans[index])
              }              
            }            
            //console.log("CASOS " + this.casos.length)
            this.allcasos=this.casos       
            //console.log("ALL casos" + this.allcasos.length)     
              for (var i = 0; i < this.casos.length; i++) {
                this.casos[i].MontoSolicitado= this.casos[i].MontoSolicitado.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                for (var j = 0; j < this.destinos.length; j++) {
                  if (this.destinos[j].Id == this.casos[i].Destino) {
                      this.casos[i].DestinoNombre=(this.destinos[j].Nombre).toLowerCase()
                  }
                }
                for (var k = 0; k < this.estatus.length; k++) {
                  if (this.estatus[k].Id == this.casos[i].Estatus) {
                      this.casos[i].EstatusNombre=(this.estatus[k].Nombre).toLowerCase()
                  }
                }             
            }					

					}else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaCasos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
          this.$vs.loading.close('#casos > .con-vs-loading')
				}			
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
			}) 
    },
    getCasosPorFiltro(){
      this.casos = []
      this.allcasos = []
      let self=this
			var objRequestListaCasos = {
				strApiKey: this.key,
				strMetodo: 'ListaCasosPorFiltro',
        objFiltro:{
          filtroCasos: this.filtroCasos
        }
			}
			this.$axios.post('/',objRequestListaCasos,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			.then(
				response => {
					if(response.data.intEstatus == 0){
            this.allcasos=response.data.objContenido
            //this.cuentaV3Pedidos()
            for (var i = 0; i < this.allcasos.length; i++) {
							this.allcasos[i].MontoSolicitado= this.allcasos[i].MontoSolicitado.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              // formato numerico valorinmueble
							this.allcasos[i].ValorInmueble= this.allcasos[i].ValorInmueble.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
							for (var j = 0; j < this.destinos.length; j++) {
								if (this.destinos[j].Id == this.allcasos[i].Destino) {
										this.allcasos[i].DestinoNombre=(this.destinos[j].Nombre).toLowerCase()
								}
							}
							for (var k = 0; k < this.estatus.length; k++) {
								if (this.estatus[k].Id == this.allcasos[i].Estatus) {
										this.allcasos[i].EstatusNombre=(this.estatus[k].Nombre).toLowerCase()
								}
							}    
						}
                this.casos = this.allcasos
                this.$vs.loading.close('#casos > .con-vs-loading')
              }else {
                this.$vs.notify({
                  title:'Ocurrio un error en ListaCasos',
                  text:response.data.strError,
                  color:'danger',
                  position:'top-right'
                })
              }
            }
          ).catch(function (error) {
            self.$vs.notify({
              title:'Ocurrio un error de sistema4',
              text:error,
              color:'danger',
              position:'top-right'
            })
          })
      },
    exportToExcel () {
      import('@/vendor/Export2Excel').then(excel => {
        let items=[]
        for(let i=0;i<this.allcasos.length;i++){
          let itemTabla=[]
          itemTabla.Id=this.allcasos[i].Id
          itemTabla.BrokerNombre=this.allcasos[i].BrokerNombre
          itemTabla.Fecha=this.allcasos[i].Fecha
          itemTabla.SolicitanteNombre=this.allcasos[i].SolicitanteNombre
          itemTabla.Solicitante=this.allcasos[i].Solicitante
          itemTabla.Telefono=this.allcasos[i].SolicitanteTelefonoCelular
          itemTabla.DestinoNombre=this.allcasos[i].DestinoNombre
          itemTabla.EstatusNombre=this.allcasos[i].EstatusNombre
          itemTabla.MontoSolicitado='$ '+this.allcasos[i].MontoSolicitado
          items.push(itemTabla)
        }
        const list = items
        const data = this.formatJson(this.headerVal, list)
        excel.export_json_to_excel({
          header: this.headerTitle,
          data,
          filename: this.fileName,
          autoWidth: this.cellAutoWidth,
          bookType: this.selectedFormat
        })
        this.clearFields()
      })
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        return v[j]
      }))
    },
    clearFields () {
      this.filename = ''
      this.cellAutoWidth = true
      this.selectedFormat = 'xlsx'
    },
    statusTabs(statusSisec){
        
    }
	}
}
</script>
<style lang="css" scoped>
.contact-bold{
  color: #4891ff;
  font-weight: 500;
}
 /*.vista{padding-top: 80px;}*/
.perfil-margin{
  padding-top: 80px;
}
.vs-input {
    padding: 0px;
    border-radius: 0 5px 5px 0 !important;}

.vs-input2 {
    padding: 0px;
    border-radius: 5px !important;
    border: none;
    max-width: 190px !important;
    margin-left: -30px;

    }
.vs-input3 {
    padding: 0px;
    border-radius:0 5px 5px 0 !important;
    border: none;
    margin-top: 10px;
    max-width: 180px !important;
    margin-left: -6px;

    }
    select{padding: 0.55rem !important;}
.alinear{
  margin-bottom: 10px;
}
.lupa {
    margin-top: 10px;
    border: none;
    padding: 6px;
    background: #fff;
    border-radius: 5px 0 0 5px;
        -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: 0 0 0 0 rgb(0 0 0 / 15%);
    box-shadow: 0 0 0 0 rgb(0 0 0 / 15%);
    margin-left: 210px;
}

input.input-search.vs-table--search-input{
  /*padding: 12px
  !important;*/
}
 .vs-table--search-input {
     border: none !important;}
      

select#estatus.search-input{border: none !important;
}


.w-full{padding-top: 0 !important;}
.excel{
  float: right;
    margin-right: 20px;
      position: relative;
      top: -50px;
      font-weight: 600;
  }
  @media screen and (max-width:896px) {

    .navbar-custom .vs-con-items {
    padding-left: 0px;}


  .footer{
    margin-top: 0;
    padding-top: 10px;
    background-color: #fff;
  }  
  .vs-input{
    border: 1px solid rgba(0,0,0,.04);
  }
     .vs-input2,
  .lupa{
    margin-left: 0px;
    background-color: #fff !important;
    border: 1px solid rgba(0,0,0,.04);
  }
  select#estatus.search-input{
    border: 1px solid rgba(0,0,0,.04) !important;
    margin-left: auto;
  }
  .vs-input2,
  .vs-input3{
     max-width: 100% !important;
     width: 100%;
     background-color: #fff !important;
     border: 1px solid rgba(0,0,0,.04) ;
      }
      .vs-input3{
        border: 1px solid rgba(0,0,0,.04) !important;
      }
      .alinear-input{
        margin-right: auto;
        margin-left: auto;
      }

  div#casos{
padding: 0px !important;
  }
    .col-md-2{
      width: 100%;
    }
  }
    @media screen and (max-width:656px) {
    .navbar-custom .vs-con-items {
    padding-left: 0px;}}

  /*Nuevos estilos*/

  .seccion-home-inmo{
    background-color:#F2F2F2;
    border-radius: 7px;
    padding: 15px;
}

.SelArrBg select {
    background: transparent;
    width: 150%;
    padding: 5px 20px;
    font-size: 22px;
    border: 1px solid #ccc;
}
.SelArrBg select option {
    color: #000;
    padding: 0 10px;
    background-color: red;
}
.SelArrBg {
    width: 100%;
    border: 1px solid #ced4da;
    overflow: hidden;
    background: url(../../src/assets/iconos/select.svg) right center no-repeat #fff;
    background-size: 1.5rem !important;
    border-radius: .25rem;
    -moz-appearance: none;
    -webkit-appearance:none;
    -ms-appearance:none;
    -o-appearance:none;
    appearance:none;
}
.collapse{
  margin-left: 0px !important;
}
.items-filtrado{
  border: solid 1px #8B8B8B;
  width: 79.8%;
  padding: .3rem;
  z-index: 1;
  background-color: white;
}
.item-configuracion{
  padding-left: .5rem;
  padding-right: .5rem;
  background-color: #F2F2F2;
  align-items: center;
  border-radius: 5px;
  height: 25px;
}
.font-item{
  font-size: 10px;
  text-align: center;
}
.btn-blue {
    color: #fff;
    background-color: #079DEF !important;
    border-color: #079DEF;
    margin-left: 0px !important;
}

.btn-green-configura{
  color: #fff;
  background-color: #4ED176 !important;
  border-color: #4ED176;
  margin-left: 0px !important;
}
.bg-btn-copiar{
  background-color: #8B8B8B;
  color: #fff;
  border: none;
}
.regresar{
  background-color: #fff;
  color: #FF7150;
  border: none;
}
.tabla-bg-color{
  background-color:#F2F2F2;
  text-align: center;
}
.tabla-bg-color_thead{
  background-color:white;   
}
.search-label {
  width: 40px;
  height: 40px;
  background-image: url('../assets/iconos/select.svg');
  text-indent: -9999px;
}
.ms-n5 {
    margin-left: -40px;
}
.router-view {
    padding: 0px;
}
  
</style>

<style>
div#tablaInmo .vs-table--tbody-table .tr-values:not(.activeEdit):not(.tr-expandedx):not(.hoverFlat):hover {
    z-index: 200;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);    
}

div#tablaInmo .vs-table--pagination .item-pagination {
    width: 30px;
    height: 30px;
    font-size: .8rem;
    border-radius: 0px;
    border: solid 1px #F2F2F2 !important;
}

div#tablaInmo .vs-pagination--li {
    margin: 0px;
    background-color: white !important;
}
div#tablaInmo .vs-pagination--ul {
    padding: 0;
    border-radius: 5% !important;
    background-color: white !important;
}
div#tablaInmo .vs-pagination-primary .effect, .vs-pagination-primary .vs-pagination--buttons:hover {
    background-color: #4ED176 !important;
    border-radius: 0 !important;
}

div#tablaInmo .vuesax-app-is-ltr .vs-pagination--buttons.vs-pagination--button-prev, .vuesax-app-is-rtl .vs-pagination--buttons.vs-pagination--button-next {
    background-color: #4ED176;
}

div#tablaInmo .vs-pagination--buttons {
  background-color: #4ED176;
}
div#tablaInmo .vs-con-table .vs-table--header .vs-table--search .vs-table--search-input {
    padding: 6px 26px !important;
    /*margin-top: -25px;*/
}

div#tablaInmo .vs-table--search i {
    left: 10px;
    top: auto;
    /*margin-top: -10px !important;*/
}
div#tablaInmo .vs-table--search-input {
    
    border-radius: 10px;
    /*margin-top: -5px;*/
    
}
/*div#tablaInmo .vs-icon{
    margin-top:  !important;
}*/


/********* Estilos responsivos *********/
.tabla-clientes-inmob {
  margin-right: 0;
}

.tabla-clientes-inmob .header-table {
  width: 100%;
  display: flex !important;
  flex-direction: column;
  background-color:#F2F2F2;
  border-radius: 7px;
  margin-top: 0;
  margin-bottom: 0;
  padding: 1.25rem 1rem;
}

.tabla-clientes-inmob .header-table .con-input-search {
  display: flex;
  align-items: center;
  top: auto;
  margin: 0;
}

/**** THEAD de la tabla ****/
.tabla-clientes-inmob .vs-con-tbody .vs-table--tbody-table .vs-table--thead th {
  padding: 0;
}

.tabla-clientes-inmob .vs-table--thead .vs-table-text,
.tabla-clientes-inmob .vs-table--thead .layout--main {
  width: 100%;
}

.tabla-clientes-inmob-head .tabla-head-opciones {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 1rem;
}

.tabla-clientes-inmob-head .tabla-head-botones {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

#tablaInmo .tabla-clientes-inmob-head .btn-descarga {
  margin-left: 0 !important;
}

.tabla-head-collapse {
  flex-direction: column;
  flex-wrap: nowrap;
}

.tabla-head-collapse-wrp {
  margin-left: 0;
}

.btn-guardar-config-wrp {
  width: auto;
  order: 1;
}

.tabla-head-collapse .collapse-opciones {
  width: 100%;
}

/*** Paginación ***/
.tabla-clientes-inmob .con-pagination-table {
  padding-left: 0;
  padding-right: 0;
  max-width: 100%;
  overflow-x: auto;
}

.tabla-clientes-inmob .vs-pagination--mb {
  width: 100% !important;
  margin-left: 0 !important;
}

@media screen and (max-width: 915px) {
  .tabla-head-botones, .tabla-head-collapse-wrp  {
    padding: 0 !important;
  }

  .tabla-clientes-inmob-head .tabla-head-opciones {
    margin-bottom: 0;
    padding: 20px;
  }
  
  .tabla-head-collapse .btn-guardar-config-wrp {
    margin-bottom: 0px !important;
  }

  /*** Paginación ***/
  .tabla-clientes-inmob .con-vs-pagination .vs-pagination--li {
    color: rgba(0,0,0,.5);
  }

  .tabla-clientes-inmob .con-vs-pagination .vs-pagination--li.is-current {
    color: #FFF;
  }
}

@media screen and (max-width: 767px) {
  .tabla-clientes-inmob .tabla-clientes-inmob-head .vs-table-text {
    width: calc(100vw - 4.5rem);
  }
}

@media screen and (max-width: 656px) {
  .tabla-clientes-inmob .header-table {
    margin-top: 0;
  }

  .tabla-clientes-inmob .header-table .con-input-search {
    top: auto;
    margin: 0;
  }

  .tabla-clientes-inmob .tabla-clientes-inmob-head .vs-table-text {
    width: calc(100vw - 2.5rem);
  }

  /*** Paginación ***/
  .tabla-clientes-inmob .vs-pagination--mb {
    justify-content: flex-start !important;
  }
}

@media screen and (max-width: 576px) {
  .clientes-inmob > div > .router-view {
    padding: 0 !important;
  }
}

@media screen and (max-width: 459px) {
  .tabla-clientes-inmob-head .tabla-head-botones {
    flex-direction: column;
    align-items: center;
  }

  .tabla-head-botones .btn-green-configura {
    margin-right: 0 !important;
    margin-bottom: 1rem;
  }
}

@media screen and (min-width: 576px) {
  .tabla-clientes-inmob .header-table {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media screen and (min-width: 768px) {
  .tabla-clientes-inmob .header-table {
    align-items: baseline;
  }
}

@media screen and (min-width: 992px) {
  /**** THEAD de la tabla ****/
  .tabla-head-collapse {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .btn-guardar-config-wrp {
    order: 0;
  }

  .tabla-head-collapse .collapse-opciones {
    width: 75%;
  }
}
</style>
