<template>

  <div class="relative">
 

    <div class="vx-navbar-wrapper" :class="classObj">

      <!--<vs-navbar class="vx-navbar navbar-custom navbar-skelton" :color="navbarColorLocal" :class="textColor">-->

        <!-- SM - OPEN SIDEBAR BUTTON -->
        <!-- <feather-icon class="sm:inline-flex xl:hidden cursor-pointer p-2" icon="MenuIcon" @click.stop="showSidebar" /> -->
        <!--<vx-tooltip :text="'Botón de inicio'" position="left">
        <feather-icon icon="HomeIcon" @click="$router.push('/').catch(() => {})" v-if="current"/>
        </vx-tooltip>
        <vs-spacer />-->
        <vs-navbar class=" navbar-custom navbar-skelton">
            
        <div :text="'Botón de inicio'" class="logo-position"   position="left" > <!--Cambiar logo style="border:	#006D4E 2px solid;
                border-radius: 5px;"-->
         <a  class=" header-logo" @click="$router.push('/')">  <img src="../../../assets/images/pages/logo-soc-nuevo.png" alt="logo-soc"  style="margin: .5rem; height: 3.6rem; cursor:pointer;"/> </a><!--Cambiar logo style="margin: .5rem; height: 3.6rem;"-->
        </div>
        <vs-spacer />

        <!-- <search-bar />-->
        <!-- <feather-icon icon="TruckIcon" @click="$router.push('/autos').catch(() => {})" style="margin-right:2%"/> -->
        <!-- <feather-icon icon="HomeIcon" @click="$router.push('/autos').catch(() => {})" style="margin-right:2%" v-if="current"/> -->
        <!-- <notification-drop-down /> -->
        <div :text="'Menú para el perfil'" position="bottom">
        <profile-drop-down />
        </div>
      </vs-navbar>
    </div>
  </div>
</template>


<script>
import Bookmarks            from "./components/Bookmarks.vue"
import SearchBar            from "./components/SearchBar.vue"
import NotificationDropDown from "./components/NotificationDropDown.vue"
import ProfileDropDown      from "./components/ProfileDropDown.vue"

export default {
  name: "the-navbar-vertical-inmobiliaria",
  props: {
    navbarColor: {
      type: String,
      default: "#fff",
    },
  },
  components: {
    Bookmarks,
    SearchBar,
    NotificationDropDown,
    ProfileDropDown,
  },
  data(){
		return{
      current:'/',
    }
  },
  // mounted(){
  //
  // },
  watch:{
    $route:function() {
      if(this.$router.currentRoute.path == '/'){
        this.current= false
      }else {
        this.current= true
      }
    }
  },
  computed: {
    navbarColorLocal() {
      return this.$store.state.theme === "dark" && this.navbarColor === "#fff" ? "#10163a" : this.navbarColor
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth
    },
    textColor() {
      return {'text-white': (this.navbarColor != '#10163a' && this.$store.state.theme === 'dark') || (this.navbarColor != '#fff' && this.$store.state.theme !== 'dark')}
    },
    windowWidth() {
      return this.$store.state.windowWidth
    },

    // NAVBAR STYLE
    classObj() {
      if (this.verticalNavMenuWidth == "default")      return "navbar-default"
      else if (this.verticalNavMenuWidth == "reduced") return "navbar-reduced"
      else if (this.verticalNavMenuWidth == 'full')    return "navbar-full"
    },
  },
  methods: {
    showSidebar() {
      this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true);
    }
  }
}
</script>
<style >

</style>
<style scoped media="screen">
.vx-navbar-wrapper{position: fixed;}
.navbar-full{
  width: 100% !important;
}
/*.header {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    box-shadow: none;
    background-color: #033A74;
    position: fixed;
    height: 60px!important;
    overflow: hidden;
    z-index: 10;
}*/
.main {
    margin: 0 auto;
    display: block;
    height: 100%;
    margin-top: 60px;
}
.mainInner{
    display: table;
    height: 100%;
    width: 100%;
    text-align: center;
}
.mainInner div{
    display:table-cell;
    vertical-align: middle;
    font-size: 3em;
    
    letter-spacing: 1.25px;
}
@media screen and (max-width:896px) {
  .logo-position{
    margin-left: auto;
  }
  .navbar-custom .vs-con-items{
    padding-left: 0px;
  }
  
}
</style>
