<template lang="html">
    <div class="layout--main">
    
        <div class="row tabla-head-opciones">
            <div class="flex p-0 tabla-head-botones">
                <button class="btn mr-8 btn-green-configura w-64" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                    <svg xmlns="http://www.w3.org/2000/svg" height="25"  width="25" viewBox="0 -960 960 960"  fill="#fff"><path d="M705-128 447-388q-23 8-46 13t-47 5q-97.083 0-165.042-67.667Q121-505.333 121-602q0-31 8.158-60.388Q137.316-691.777 152-718l145 145 92-86-149-149q25.915-15.158 54.957-23.579Q324-840 354-840q99.167 0 168.583 69.417Q592-701.167 592-602q0 24-5 47t-13 46l259 258q11 10.957 11 26.478Q844-209 833-198l-76 70q-10.696 11-25.848 11T705-128Zm28-57 40-40-273-273q16-21 24-49.5t8-54.5q0-75-55.5-127T350-782l101 103q9 9 9 22t-9 22L319-511q-9 9-22 9t-22-9l-97-96q3 77 54.668 127T354-430q25 0 53-8t49-24l277 277ZM476-484Z"/></svg>
                    Configura tu base
                </button>
            
                <button class="btn btn-blue-D w-64 btn-descarga" type="button" v-if="etiqueta !='Precalifica'" @click="$emit( 'Download', true )"> 
                    <svg xmlns="http://www.w3.org/2000/svg" height="25"  width="25" viewBox="0 -960 960 960"  fill="#fff"><path d="M280-280h400v-60H280v60Zm197-126 158-157-42-42-85 84v-199h-60v199l-85-84-42 42 156 157Zm3 326q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z"/></svg>
                    Descarga
                </button>            

            </div>

            <div class="collapse p-0 tabla-head-collapse-wrp" id="collapseExample">          
            <!--  <div class="mt-8 flex">-->
                <div class="row tabla-head-collapse" style="padding-top: 20px;"> 
                    <div class="col-sm-2 btn-guardar-config-wrp" style="margin: 13px 0 10px 0px;">
                        <button class="btn btn-blue btn-guardar-config" type="button" @click="insertUpdateColumns()" data-bs-toggle="collapse" data-bs-target="#collapseExample">
                            Guardar configuraci&oacute;n
                        </button>
                    </div>
                
                    <div class="col-sm-9 collapse-opciones">
                        <v-select 
                            v-model="columnasSelected"
                            multiple 
                            :closeOnSelect="false"
                            :options="columnas" 
                            class="custom-select"
                            :dir="$vs.rtl ? 'rtl' : 'ltr'"
                        />
                    </div>
                </div>          
            </div>
        </div>
    </div>


</template>

<script>
import TheNavbarVerticalInmobiliaria   from '@/layouts/components/navbar/TheNavbarVerticalInmobiliaria.vue'
import vSelect from 'vue-select'
export default {
  props: {
    filtroCasos: {
        type: String,
        required: false
    },
    etiqueta: {
        type: String,
        required: false
    },
    tabla: {
        type: Number,
        required: false
    },
  },
  components: {
    TheNavbarVerticalInmobiliaria,
    'v-select': vSelect,
  },
  data(){
		return{      
            key:'',
            columnas : [
                { label: 'Folio', id: 1 },
                { label: 'Origen', id: 2 },
                { label: 'Fecha ingreso', id: 3 },
                { label: 'Ejecutivo', id: 4 },
                { label: 'Organización', id: 5 },            
                { label: 'Cliente', id: 6 },
                { label: 'Contacto', id: 7 },            
                { label: 'Destino', id: 8 },
                { label: 'Valor Inmueble', id: 9 }
            ],

            columnasSelected : [
                { label: 'Folio', id: 1 },
                { label: 'Origen', id: 2 },
                { label: 'Fecha ingreso', id: 3 },
                { label: 'Ejecutivo', id: 4 },
                { label: 'Organización', id: 5 },                
                { label: 'Cliente', id: 6 },
                { label: 'Contacto', id: 7 },            
                { label: 'Destino', id: 8 },
                { label: 'Valor Inmueble', id: 9 },  
            ],
            ColumnasSelected: [],
		}
  },
	mounted(){
        if(!JSON.parse(localStorage.getItem('userInfo'))){
        this.$router.push('/login').catch(() => {})
        }else{
            this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
        }

      /*  if (this.etiqueta == 'Precalifica') {
            this.columnas = [
            { label: 'Folio', id: 1 },
            { label: 'Origen', id: 2 },
            { label: 'Fecha ingreso', id: 3 },
            { label: 'Ejecutivo', id: 4 },            
            { label: 'Perfil', id: 5 },
            { label: 'Cliente', id: 6 },
            { label: 'Contacto', id: 7 },            
            { label: 'Destino', id: 8 },
            { label: 'Valor Inmueble', id: 9 },
        ],
        this.columnasSelected = [
                { label: 'Folio', id: 1 },
                { label: 'Origen', id: 2 },
                { label: 'Fecha ingreso', id: 3 },
                { label: 'Ejecutivo', id: 4 },                
                { label: 'Perfil', id: 5 },
                { label: 'Cliente', id: 6 },
                { label: 'Contacto', id: 7 },            
                { label: 'Destino', id: 8 },
                { label: 'Valor Inmueble', id: 9},  
            ]
        }else{
            this.columnas = [
            { label: 'Folio', id: 1 },
            { label: 'Origen', id: 2 },
            { label: 'Fecha ingreso', id: 3 },
            { label: 'Ejecutivo', id: 4 },
            { label: 'Organización', id: 5 },            
            { label: 'Cliente', id: 6 },
            { label: 'Contacto', id: 7 },            
            { label: 'Destino', id: 8 },
            { label: 'Valor Inmueble', id: 9 },
        ],
        this.columnasSelected = [
                { label: 'Folio', id: 1 },
                { label: 'Origen', id: 2 },
                { label: 'Fecha ingreso', id: 3 },
                { label: 'Ejecutivo', id: 4 },
                { label: 'Organización', id: 5 },                
                { label: 'Cliente', id: 6 },
                { label: 'Contacto', id: 7 },            
                { label: 'Destino', id: 8 },
                { label: 'Valor Inmueble', id: 9 },  
            ]
        }*/

        this.getColumnPersonalized();    
	},
  watch: {
    filtroCasos: function() {
      this.$vs.loading({
        container: '#casos',
        scale: 0.6
      })
    
      this.getColumnPersonalized();      
    },    
  },
	methods:{    
    filter(id){ //No se ocupa en inmobiliaria
      var newArray = this.allcasos.filter(function (el) {
        return el.Estatus == id;
      });
      this.casos=newArray
    },    
    insertUpdateColumns() {      
        let self = this;
        let objInsertUpdateColumns = {
            strApiKey: this.key,
            strMetodo: 'saveColumnasPersonalizadas',
            objCols: {
                Columnas: JSON.stringify(this.columnasSelected),
                Broker: this.key,
                FiltroCaso: this.filtroCasos,
                Tabla: this.tabla,
            },
        }

        this.$axios.post('/', objInsertUpdateColumns, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        }).then( resp => {
            if (resp.data.intEstatus == 0) {
              this.getColumnPersonalized();
                this.$vs.notify({
                    title:'Configuración guardada',
                    text:'',
                    color:'success',
                    position:'top-right'
                });
            } else {
                this.$vs.notify({
                    title: 'Ocurrio un error al registrar columnas',
                    text: resp.data.strError,
                    color: 'danger',
                    position: 'top-right'
                });
            }

            // console.log( 'Petición API', resp );
        }).catch(function (error) {
            self.$vs.notify({
                title: 'Ocurrio un error de sistema',
                text: error,
                color: 'danger',
                position: 'top-right'
            });
        });
        // console.log( objInsertUpdateColumns );
    },
    getColumnPersonalized(){
      let objSearchColsCompleted = {
          strApiKey: this.key,
          strMetodo: 'getColumnasPersonalizadas',
          objCols: {
              Broker: this.key,
              FiltroCaso: this.filtroCasos,
              Tabla: this.tabla,
          },
      }
      this.$axios.post('/', objSearchColsCompleted, { 
                headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } 
            }).then(
                resp => {
                    if (resp.data.intEstatus == 0) {
                        // Si la petición es diferente de null y devuelve datos obtengo la data de tabla 0
                        if (resp.data.objContenido && resp.data.objContenido.length > 0) {
                            this.ColumnasSelected = JSON.parse(resp.data.objContenido[0].Columnas);
                            this.columnasSelected = this.ColumnasSelected        
                            this.sendFatherCols();
                          }else{                            
                            this.ColumnasSelected = this.columnas
                            this.columnasSelected = this.columnas
                            this.sendFatherCols();
                          }
                    } else {
                        this.$vs.notify({
                            title: 'Ocurrio un error al obtener columnas',
                            text: resp.data.strError,
                            color: 'danger',
                            position: 'top-right'
                        });
                    }                    
                }
            ).catch(function (error) {
                    self.$vs.notify({
                    title: 'Ocurrio un error de sistema',
                    text: error,
                    color: 'danger',
                    position: 'top-right'
                });
            });

    },
    searchColTable( col ) { 
        let isEnabled = this.ColumnasSelected.find(e => e.label == col);
        return isEnabled;
    },     
    sendFatherCols() {             
        this.$emit( 'colsSelected', this.columnasSelected );
    },
	}
}
</script>

<style lang="css" scoped>
.btn-green-configura{
  color: #fff;
  background-color: #4ED176 !important;
  border-color: #4ED176;
  margin-left: 0px !important;
}
.btn-blue {
    color: #fff;
    background-color: #079DEF !important;
    border-color: #079DEF;
    margin-left: 0px !important;
}
.btn-blue-D {
    color: #fff;
    background-color: #079DEF !important;
    border-color: #079DEF;
    margin-left: 800px !important;
}
</style>